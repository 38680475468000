// import React, { useState } from "react";
// import { Modal } from "react-bootstrap";
// import "./Membership_card_modal.css";
// import Membership_card from "../../Common-Component/Membership_card/Membership_card";
// import Membership__Modal_card from "../../Common-Component/Membership__Modal_card/Membership__Modal_card";

// function Membership_card_modal(props) {
//   return (
//     <>
//       <div className="Successfull_Modal_sec ">
//         <Modal
//           className="Successfull_Modal Membership_card_modal"
//           {...props}
//           size="md"
//           aria-labelledby="contained-modal-title-vcenter"
//           centered
//         >
//           <Modal.Header closeButton></Modal.Header>
//           <Modal.Body>
//             <Membership__Modal_card
//               headingText={props.headingText}
//               description={props.description}
//               amountText={props.amountText}
//               btnText={props.btnText}
//             />
//           </Modal.Body>
//         </Modal>
//       </div>
//     </>
//   );
// }

// export default Membership_card_modal;

// import React, { useState } from "react";
// import { Modal } from "react-bootstrap";
// import "./Membership_card_modal.css";
// import Membership_card from "../../Common-Component/Membership_card/Membership_card";
// import Membership__Modal_card from "../../Common-Component/Membership__Modal_card/Membership__Modal_card";

// function Membership_card_modal(amountText, headingText, btnText, description) {
//   return (
//     <>
//       <div className="Successfull_Modal_sec ">
//         <Modal
//           className="Successfull_Modal Membership_card_modal"
//           size="md"
//           aria-labelledby="contained-modal-title-vcenter"
//           centered
//         >
//           <Modal.Header closeButton></Modal.Header>
//           <Modal.Body>
//             <Membership__Modal_card amountText={amountText} />
//           </Modal.Body>
//         </Modal>
//       </div>
//     </>
//   );
// }

// export default Membership_card_modal;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./Membership_card_modal.css";
import Membership_card from "../../Common-Component/Membership_card/Membership_card";
import Membership__Modal_card from "../../Common-Component/Membership__Modal_card/Membership__Modal_card";

function Membership_card_modal(props) {
  return (
    <>
      <div className="Successfull_Modal_sec ">
        <Modal
          className="Successfull_Modal Membership_card_modal"
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Membership__Modal_card
              headingText={props.headingText}
              details={props.details}
              btnText={"View Less"}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Membership_card_modal;
