import React, { useState, useEffect, useContext } from "react";
import "./blogbannn.css";
import { getAllBlogVideo } from "../../../utils/apis/common/Common";
import { Context } from "../../../utils/context";
import Blog from "../Blog";

const Blog_vdo = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [blog, setBlog] = useState();
  const getProjectTwo = async () => {
    const res = await getAllBlogVideo();
    if (res?.success) {
      setBlog(res?.data);
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getProjectTwo();
  }, []);

  return (
    <>
      <section className="blogvdo-sec mb-2">
        <div className="container p-0">
          <div className="vdooseecc mt-3">
            {blog?.map((post, index) => (
              <video className="video" controls autoPlay loop mute>
                <source className="vdooo" src={IMG_URL + post?.video}></source>
              </video>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog_vdo;
