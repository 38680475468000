import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./Delete_Transactions.css";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";

function Delete_Transactions(props) {
  const [modalShow, setModalShow] = useState(false);
  const { message } = props; // Extracting 'message' prop
  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal Delete_Transactions"
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>{message}</p> {/* Displaying the 'message' prop */}
            <div className="btn-holder">
              <Common_Button
                className={"SubmitBtn"}
                text={"Cancle"}
                background={"#ccc"}
                color={"#000"}
              />
              <Common_Button
                className={"SubmitBtn"}
                text={"Ok"}
                onClick={() => {
                  setModalShow(true);
                  props.onHide();
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <Successfull_Modal
        message=" Transactions Delete successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Delete_Transactions;
