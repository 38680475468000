import React, { useEffect } from 'react'
import ProductBanner from '../product-banner/ProductBanner'
import AllProductDetails from './All-product-details/AllProductDetails'

const ViewAllProduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  return (
    <>
      <ProductBanner />
      <AllProductDetails/>
    </>
  )
}

export default ViewAllProduct