import React, { useState, useEffect, useContext } from "react";
import "./WhyChoose.css";
import { Context } from "../../../utils/context";
import { getwhychooseus } from "../../../utils/apis/common/Common";
import parse from "html-react-parser";
const WhyChoose = () => {
  const [whyChooseUs, setWhyChooseUs] = useState();
  const { getData, IMG_URL } = useContext(Context);
  const getProject = async () => {
    const res = await getwhychooseus();
    if (res?.success) {
      setWhyChooseUs(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const features = [
    {
      icon: "/assets/Image/about/free-shipping.png",
      title: "Free Shipping",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr.",
    },
    {
      icon: "/assets/Image/about/coin.png",
      title: "100% Money Back Guarantee",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr.",
    },
    {
      icon: "/assets/Image/about/headphones.png",
      title: "Online Support 24/7",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr.",
    },
  ];
  return (
    <>
      <section className="why-choose">
        <div className="text-center">
          <h3 className="hot-title">Why Choose Us</h3>
          <div className="title-border"></div>
        </div>
        <div className="container mt-5">
          <div className="row">
            {whyChooseUs?.map((data, index) => (
              <div
                key={index}
                className={"col-xxl-4 col-xl-4 col-lg-4 col-md-6 bd-right"}
              >
                <div className="d-flex choos-col">
                  <div className="choose-fafa-bg text-center">
                    <img
                      src={IMG_URL + data?.logo}
                      className="choose-fafa-icons"
                      alt={data?.name}
                    />
                  </div>
                  .
                  <div className="main ms-3">
                    <h6 className="title">{data?.name}</h6>
                    <p className="sub-text">
                      {htmlToReactParser.parse(data?.description)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChoose;
