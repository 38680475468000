import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext, useEffect } from "react";

import "./Heart_Component.css";
import { Button, Card } from "react-bootstrap";

import { Context } from "../../../utils/context";

import { useNavigate } from "react-router-dom";
import { addWishlist } from "../../../utils/apis/Wishlist/Wishlist";

function Heart_Component({ product, variant, m_varient }) {
  const {
    wishlistData,
    signin,
    setShimmerLoader,
    shimmerLoader,
    wishlistContext,
  } = useContext(Context);
  const navigate = useNavigate();
  const handleHeartClick = async () => {
    // setShimmerLoader(true);
    if (signin) {
      const res = await addWishlist({
        product_id: product?.id,
        variant_id: variant?.id,
        m_variant_id: m_varient?.id,
      });
      if (res?.success) {
        wishlistContext();
      }
      // setShimmerLoader(false);
    } else {
      // setShimmerLoader(false);
      navigate("/login");
    }
  };

  return (
    <>
      <div className="hearticn" onClick={handleHeartClick}>
        <FontAwesomeIcon
          icon={faHeart}
          className={
            wishlistData?.some(
              (item) =>
                (item.product_id === product?.id &&
                  item.variant_id === variant?.id) ||
                (item.product_id === product?.id &&
                  item.m_variant_id === m_varient?.id)
            )
              ? "gobiheartss"
              : "gobiheart"
          }
        />
      </div>
    </>
  );
}

export default Heart_Component;
