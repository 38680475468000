import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form, InputGroup } from "react-bootstrap";
import classNames from "classnames";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { RegxExpression } from "../../../../utils/common";
import { Errors, Placeholders } from "../../../../utils/errors";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Country,
  State,
  City,
  Pincode,
  allPincode,
} from "../../../../utils/apis/master/Master";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
// import "./Stepfour.css";
import {
  sendMobileOTP,
  resendMobileOTP,
  sendEmailOTP,
  verifyMobileOTP,
  verifyEmailOTP,
} from "../../../../utils/apis/common/Common";
import MobileOtpModal from "../../../Common_modal/Mobile_otp_modal/MobileOtpModal";

const Stepone = ({ props, prevStep, nextStep }) => {
  const [selectedRole, setSelectedRole] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [sendOtpError, setSendError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const [showPassword1, setShowPassword1] = useState(false);
  const togglePasswordVisibility1 = () => {
    setShowPassword1((prevState) => !prevState);
  };

  const handleRadioChange = (e) => {
    setSelectedRole(e.target.id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { getData, IMG_URL, Select2Data, postData } = useContext(Context);
  const [user_id, setUserId] = useState("");

  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [otpErr, setOtpErr] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    if (data.password === data.c_password) {
      const formData = new FormData();
      if (user_id) {
        formData.append("id", user_id);
      }
      formData.append("first_name", data?.first_name);
      formData.append("email", data?.email);
      formData.append("contact_no", data?.contact_no);
      formData.append("password", data?.password);
      formData.append("role_in_store", selectedRole);
      formData.append("manager_name", data?.manager_name);
      formData.append("manager_email", data?.manager_email);
      formData.append("manager_contact_no", data?.manager_contact_no);
      formData.append("lat", data?.lat);
      formData.append("long", data?.long);
      formData.append("store_address", data?.store_address);
      formData.append("pincode_id", data?.pincode_id?.value);
      if (data?.manager_id) {
        formData.append("manager_id", data?.manager_id);
      }
      if (props?.newUserContact && props?.newUserContact !== data?.contact_no) {
        formData.append("verified", false);
      }

      try {
        const res = await postData("/seller/s-owner-details", formData);
        if (res?.success) {
          Cookies.set("user_id", res.data.id);
          props?.setNewUserContact(res?.data?.contact_no);
          nextStep();
        } else {
          if (res?.message?.both) {
            setError("email", { message: res?.message?.both });
            setError("contact_no", { message: "" });
          }
          if (res?.message?.email) {
            setError("email", { message: res?.message?.email });
          }
          if (res?.message?.contact_no) {
            setError("contact_no", { message: res?.message?.contact_no });
          }
          if (res?.message?.first_name) {
            setError("first_name", { message: res?.message?.first_name });
          }

          if (res?.message?.manager_both) {
            setError("manager_email", { message: res?.message?.manager_both });
            setError("manager_contact_no", { message: "" });
          }
          if (res?.message?.manager_email) {
            setError("manager_email", { message: res?.message?.manager_email });
          }
          if (res?.message?.manager_contact_no) {
            setError("manager_contact_no", {
              message: res?.message?.manager_contact_no,
            });
          }
          if (res?.message?.manager_name) {
            setError("manager_name", { message: res?.message?.manager_name });
          }

          if (res?.message?.pan_no) {
            setError("pan_no", { message: res?.message?.pan_no });
          }
          if (res?.message?.tax_no) {
            setError("tax_no", { message: res?.message?.tax_no });
          }
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("An error occurred while submitting data:", error);
      }
    } else {
      setError("password", {
        message: "Password Must Match",
      });
    }
  };
  const [pincode, setPincode] = useState([]);

  const getEditData = async () => {
    const res = await getData(`/seller/s-owner-details/${user_id}`);
    if (res?.success) {
      reset(res?.data);
      getPincode(res?.data);
      // if (res?.data?.manager_id) {
      setSelectedRole(res.data?.role_in_store);
      // } else {
      //   setSelectedRole("Owner");
      // }
    }
  };

  const getPincode = async () => {
    const res = await allPincode();
    if (res?.success) {
      const data = await Select2Data(res?.data, "pincode_id", false);
      setPincode(data);
    }
  };

  useEffect(() => {
    getEditData();
  }, [user_id]);

  useEffect(() => {
    getPincode();
    setUserId(Cookies.get("user_id"));
  }, []);

  const [otpLoader, setOtpLoader] = useState(false);
  const handleSendOtp = async (type, subType) => {
    setOtpLoader(true);
    if (type === "mobile") {
      if (getValues("contact_no")) {
        if (subType === "resend") {
          const res = await resendMobileOTP(getValues("contact_no"));
          if (res?.success) {
            setModalShow2(true);
            setSeconds(90);
            clearErrors();
            setSendError(false);
          } else {
            setSendError(res.message);
          }
        } else {
          const res = await sendMobileOTP(getValues("contact_no"));
          if (res?.success) {
            setModalShow2(true);
            setSeconds(90);
            clearErrors();
            setSendError(false);
          } else {
            setSendError(res.message);
          }
        }
      } else {
        setError("contact_no", {
          message: Errors.phone,
        });
      }
    } else {
      if (getValues("email")) {
        if (RegxExpression.email.test(getValues("email"))) {
          const res = await sendEmailOTP(getValues("email"));

          if (res?.success) {
            setModalShow3(true);
            setSeconds(90);
            clearErrors();
          }
        } else {
          setError("email", {
            message: "Email Invalid",
          });
        }
      } else {
        setError("email", {
          message: Errors.email,
        });
      }
    }
    setOtpLoader(false);
  };

  const [mobileVerify, setMobileVerify] = useState(false);

  const verifyOTP = async (type, otp) => {
    const formValues = getValues();
    if (type == "mobile") {
      const res = await verifyMobileOTP(getValues("contact_no"), otp);
      if (res?.success) {
        setMobileVerify(true);

        setModalShow2(false);
        clearErrors();
        setSeconds(0);
      } else {
        setOtpErr(res.message);
      }
    } else {
      const res = await verifyEmailOTP(getValues("email"), otp);
      if (res?.success) {
        setEmailVerify(true);
        setModalShow3(false);
        clearErrors();
        setSeconds(0);
      } else {
        setOtpErr(res.message);
      }
    }
  };

  return (
    <section className="personal-details-form userForm">
      <div className="details-form-holder">
        <div className="form-container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">Name of Owner*</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="Name"
                    className={classNames("", {
                      "is-invalid": errors?.first_name,
                    })}
                    {...register("first_name", {
                      required: "First Name is required",
                    })}
                  />

                  {errors.first_name && (
                    <span className="text-danger">
                      {errors.first_name.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">Owner Email ID*</Form.Label>

                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="email"
                    className={classNames("", {
                      "is-invalid": errors?.email,
                    })}
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email address",
                      },
                      validate: (value) => {
                        // Extract the domain and TLD
                        const domainPattern =
                          /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/;
                        const match = value.match(domainPattern);
                        if (match) {
                          const domainParts = match[1].split(".");
                          const tld = match[2];

                          // Ensure the domain and TLD are not the same
                          if (domainParts[domainParts.length - 1] === tld) {
                            return "Domain and top-level domain must be different";
                          }
                        }
                        return true;
                      },
                    })}
                  />

                  {errors.email && (
                    <span className="text-danger">{errors.email.message}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">
                    Owner Contact Number*
                  </Form.Label>
                  <div className="password_div">
                    <Form.Control
                      type="text"
                      name="contact_no"
                      placeholder="contact Number"
                      {...register("contact_no", {
                        required: "Contact number is required",
                        validate: (value) =>
                          value.length === 10 ||
                          "Contact number must be exactly 10 digits",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                        "is-valid":
                          !errors?.contact_no &&
                          getValues("contact_no")?.length === 10,
                      })}
                      onKeyDown={(event) => {
                        const { key } = event;
                        const value = event.target.value;

                        // Prevent non-digit characters except Backspace and Tab
                        if (
                          !/[0-9]/.test(key) &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }

                        // Prevent input if length exceeds 10 digits
                        if (
                          value?.length >= 10 &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />{" "}
                    {!mobileVerify && !otpLoader && (
                      <div className="send-otp-text-holder">
                        <p
                          className="telepara"
                          onClick={() => handleSendOtp("mobile", "send")}
                        >
                          Send OTP
                        </p>
                      </div>
                    )}
                  </div>
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {sendOtpError?.contact_no}
                  </span>
                  <MobileOtpModal
                    show={modalShow2}
                    seconds={seconds}
                    otpErr={otpErr}
                    setSeconds={setSeconds}
                    verifyOTP={verifyOTP}
                    handleSendOtp={() => handleSendOtp("mobile", "resend")}
                    onHide={() => setModalShow2(false)}
                  />
                  {errors.contact_no && (
                    <span className="text-danger">
                      {errors.contact_no.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">Create Password*</Form.Label>
                  <div className="password_div">
                    <Form.Control
                      type={!showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Create a Password"
                      {...register("password", {
                        required: "Password required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.password,
                        "is-valid": getValues("password"),
                      })}
                    />
                    <div className="password-eye-holder">
                      <FontAwesomeIcon
                        icon={!showPassword ? faEyeSlash : faEye}
                        className="eyeicn"
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                  </div>
                  {errors.password && (
                    <span className="text-danger">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required">
                    Confirm Password*
                  </Form.Label>
                  <div className="password_div">
                    <Form.Control
                      type={!showPassword1 ? "text" : "password"}
                      name="c_password"
                      placeholder="Confirm Password"
                      {...register("c_password", {
                        required: "Confirm Password required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.password,
                        "is-valid": getValues("c_password"),
                      })}
                    />
                    <div className="password-eye-holder">
                      <FontAwesomeIcon
                        icon={!showPassword1 ? faEyeSlash : faEye}
                        className="eyeicn"
                        onClick={togglePasswordVisibility1}
                      />
                    </div>
                  </div>
                  {errors.c_password && (
                    <span className="text-danger">
                      {errors.c_password.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="field-bottom">
                  <Form.Label className="required form-field">Role*</Form.Label>
                  <div className="row get-otp-text">
                    <div className="col-lg-6">
                      <Form.Check
                        type="radio"
                        label="Owner"
                        id="Owner"
                        checked={selectedRole === "Owner"}
                        name="role"
                        onChange={handleRadioChange}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Form.Check
                        type="radio"
                        label="Manager"
                        id="Manager"
                        name="role"
                        checked={selectedRole === "Manager"}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {selectedRole === "Owner" && <div className="row"></div>} */}

            {selectedRole === "Manager" ? (
              <div className="row">
                <div className="col-md-6">
                  <div className="field-bottom">
                    <Form.Label className="required form-field">
                      Manager Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="manager_name"
                      placeholder="Manager Name"
                      className={classNames("", {
                        "is-invalid": errors?.manager_name,
                      })}
                      {...register("manager_name", {
                        required: "Manager Name is required",
                      })}
                    />

                    {errors.manager_name && (
                      <span className="text-danger">
                        {errors.manager_name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-bottom">
                    <Form.Label className="required form-field">
                      Manager Email ID
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="manager_email"
                      placeholder="Manager Email"
                      className={classNames("", {
                        "is-invalid": errors?.manager_email,
                      })}
                      {...register("manager_email", {
                        required: "Manager Email is required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Invalid Manager Email address",
                        },
                        validate: (value) => {
                          // Extract the domain and TLD
                          const domainPattern =
                            /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/;
                          const match = value.match(domainPattern);
                          if (match) {
                            const domainParts = match[1].split(".");
                            const tld = match[2];

                            // Ensure the domain and TLD are not the same
                            if (domainParts[domainParts.length - 1] === tld) {
                              return "Domain and top-level domain must be different";
                            }
                          }
                          return true;
                        },
                      })}
                    />

                    {errors.manager_email && (
                      <span className="text-danger">
                        {errors.manager_email.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field-bottom">
                    <Form.Label className="required form-field">
                      Manager Contact Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="manager_contact_no"
                      placeholder="Manager Contact Number"
                      {...register("manager_contact_no", {
                        required: "Contact number is required",
                        validate: (value) =>
                          value.length === 10 ||
                          "Manager Contact number must be exactly 10 digits",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.manager_contact_no,
                        "is-valid":
                          !errors?.manager_contact_no &&
                          getValues("manager_contact_no")?.length === 10,
                      })}
                      onKeyDown={(event) => {
                        const { key } = event;
                        const value = event.target.value;

                        // Prevent non-digit characters except Backspace and Tab
                        if (
                          !/[0-9]/.test(key) &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }

                        // Prevent input if length exceeds 10 digits
                        if (
                          value?.length >= 10 &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />

                    {errors.manager_contact_no && (
                      <span className="text-danger">
                        {errors.manager_contact_no.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row"></div>
            )}

            <div style={{ width: "100%" }} className="mb-4">
              <iframe
                width="100%"
                height="600"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=profcyma%20global%20solution+(Wesbite)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/">gps trackers</a>
              </iframe>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required form-field">
                    Longitude
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="long"
                    placeholder="longitude"
                    className={classNames("", {
                      "is-invalid": errors?.long,
                    })}
                    {...register("long", {
                      required: "longitude is required",
                    })}
                  />

                  {errors.long && (
                    <span className="text-danger">{errors.long.message}</span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <Form.Label className="required form-field">
                    Latitude
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lat"
                    placeholder="latitude"
                    className={classNames("", {
                      "is-invalid": errors?.lat,
                    })}
                    {...register("lat", {
                      required: "latitude is required",
                    })}
                  />

                  {errors.lat && (
                    <span className="text-danger">{errors.lat.message}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="field-bottom">
                <Form.Label className="required form-field">
                  Store Address
                </Form.Label>
                <Form.Control
                  type="text"
                  name="store_address"
                  placeholder="Store Address"
                  className={classNames("", {
                    "is-invalid": errors?.store_address,
                  })}
                  {...register("store_address", {
                    required: "Store Address is required",
                  })}
                />

                {errors.store_address && (
                  <span className="text-danger">
                    {errors.store_address.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="field-bottom">
                <Form.Label className="required form-field">
                  Postal Code
                </Form.Label>
                <Controller
                  name="pincode_id"
                  {...register("pincode_id", {
                    required: "Select Pincode",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.pincode_id ? "red" : baseStyles,
                        }),
                      }}
                      {...field}
                      options={pincode}
                    />
                  )}
                />

                {errors.pincode_id && (
                  <span className="text-danger">
                    {errors.pincode_id.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="text-end apply_now_btn">
                <div className="registerContinueMain">
                  <Button className="tabs-btn" type="submit">
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </section>
  );
};
export default Stepone;
