import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./ShopBySeller.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "react-bootstrap";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";

import { Context } from "../../../utils/context";

import { SellerGet } from "../../../utils/apis/master/Master";
import { waitFor } from "@testing-library/react";

import { sellerCategory } from "../../../utils/apis/SellerApi/SellerApi";
const ShopBySeller = () => {
  const navigate = useNavigate();
  const handleNext = async (id) => {
    const res = await sellerCategory(id);
    if (res?.success) {
      localStorage.setItem("seller_id", id);
      navigate("/product");
    }
  };

  const { IMG_URL } = useContext(Context);
  const [seller, setSeller] = useState([]);

  const getSeller = async () => {
    const res = await SellerGet();
    if (res?.success) {
      setSeller(res?.data);
    }
  };
  useEffect(() => {
    getSeller();
  }, []);
  return (
    <>
      {/* Shop-By-Seller Section Started */}

      <section className="Shop-By-Seller">
        <Container>
          <h1 className="title">Shop by Seller</h1>
          <div className="Seller-section">
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                486: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1400: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1900: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {seller?.map((item, index) => (
                <SwiperSlide key={index} onClick={() => handleNext(item?.id)}>
                  {/* <Link to={`/product`}> */}
                  <Popular_Product_Card
                    className="main-section mb-5"
                    content={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.store_detail?.store_name,
                        }}
                      />
                    }
                    image={IMG_URL + item?.store_detail?.logo}
                    imgClassName="seller-img"
                    textClassName="local-text"
                  />
                  {/* </Link> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </section>

      {/* Shop-By-Seller Section End */}
    </>
  );
};

export default ShopBySeller;
