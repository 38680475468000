import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
const Earn_With_Us = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <section className="TermsCondition">
        <Container>
          <ul>
            <div className="list-holder">
              <p className="product-text">
                Welcome to [Your Company Name]! These terms and conditions
                outline the rules and regulations for the use of [Your Company
                Name]'s Website, located at [Your Website URL].
              </p>
            </div>

            <li className="product-text">1. Introduction</li>
            <div className="list-holder">
              <p className="product-text">
                By accessing this website we assume you accept these terms and
                conditions. Do not continue to use [Your Website Name] if you do
                not agree to take all of the terms and conditions stated on this
                page.
              </p>
            </div>

            <li className="product-text">2. Definitions </li>
            <div className="list-holder">
              <p className="product-text">
                Company: Refers to [Your Company Name], located at [Your Company
                Address].{" "}
              </p>
              <p className="product-text">
                Customer: Refers to the individual or entity that purchases
                goods from the Company.
              </p>
              <p className="product-text">
                Website: Refers to [Your Website URL].{" "}
              </p>
            </div>

            <li className="product-text">3. General Conditions </li>
            <div className="list-holder">
              <p className="product-text">
                We reserve the right to refuse service to anyone for any reason
                at any time. You understand that your content (excluding credit
                card information), may be transferred unencrypted and involve
                transmissions over various networks.{" "}
              </p>
            </div>
            <li className="product-text">
              4. Accuracy, Completeness, and Timeliness of Information
            </li>
            <div className="list-holder">
              <p className="product-text">
                We are not responsible if information made available on this
                site is not accurate, complete, or current. The material on this
                site is provided for general information only and should not be
                relied upon or used as the sole basis for making decisions.
              </p>
            </div>

            <li className="product-text">5. Products or Services</li>
            <div className="list-holder">
              <p className="product-text">
                Certain products or services may be available exclusively online
                through the website. These products or services may have limited
                quantities and are subject to return or exchange only according
                to our Return Policy.{" "}
              </p>
            </div>
            <li className="product-text">6. Pricing and Payment</li>
            <div className="list-holder">
              <p className="product-text">
                All prices are subject to change without notice. We reserve the
                right at any time to modify or discontinue the service (or any
                part or content thereof) without notice at any time. We shall
                not be liable to you or to any third-party for any modification,
                price change, suspension, or discontinuance of the Service.{" "}
              </p>
            </div>
            <li className="product-text">7. Orders and Account Information </li>
            <div className="list-holder">
              <p className="product-text">
                You agree to provide current, complete, and accurate purchase
                and account information for all purchases made at our store. You
                agree to promptly update your account and other information,
                including your email address and credit card numbers and
                expiration dates, so that we can complete your transactions and
                contact you as needed.{" "}
              </p>
            </div>
          </ul>
        </Container>
      </section>
    </>
  );
};

export default Earn_With_Us;
