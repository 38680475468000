import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LogIn from "../LogIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Errors, Placeholders } from "../../../utils/errors";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import DatePicker from "react-datepicker";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";

import { loginCart } from "../../../utils/apis/addToCart/addToCart";
import { Register } from "../../../utils/apis/login/Login";
import RegisterModal from "../../Common_modal/RegisterModal/RegisterModal";
import {
  sendMobileOTP,
  resendMobileOTP,
  sendEmailOTP,
  verifyMobileOTP,
  verifyEmailOTP,
} from "../../../utils/apis/common/Common";

import MobileOtpModal from "../../Common_modal/Mobile_otp_modal/MobileOtpModal";

function Register_Form() {
  const [showLogin, setShowLogin] = useState(false);

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const [mobileVerify, setMobileVerify] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // New state for image preview

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);

  const [seconds, setSeconds] = useState(0);
  const [otpErr, setOtpErr] = useState("");
  const [sendOtpError, setSendError] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };

  const { signin, setSignin } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const finalData = new FormData();
    finalData.append("type", data?.type);
    finalData.append("first_name", data?.first_name);
    finalData.append("dob", data?.dob);
    finalData.append("email", data?.email);
    finalData.append("contact_no", data?.contact_no);
    finalData.append("password", data?.password);
    if (data?.image && data.image.length > 0) {
      finalData.append("image", data.image[0]);
    }
    if (password === passwordAgain) {
      const res = await Register(finalData);
      if (res?.success) {
        await Cookies.set("boomer_bucks_security", res?.data?.token);
        setSignin(true);
        await loginCart();
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
          navigate("/");
        }, 3000);
      } else {
        if (res?.message?.both) {
          setError("email", { message: res?.message?.both });
          setError("contact_no", { message: "" });
        }
        if (res?.message?.email) {
          setError("email", { message: res?.message?.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", { message: res?.message?.contact_no });
        }
      }
    } else {
      setError("re_password", { message: "Password Does not match" });
    }
  };

  const todayDate = new Date().toISOString().split("T")[0];

  const handleDateChange = (date) => {
    setStartDate(date);
    setValue("dob", date, { shouldValidate: true });
  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview(null);
    }
  };
  const [otpLoader, setOtpLoader] = useState(false);
  const handleSendOtp = async (type, subType) => {
    setOtpLoader(true);
    if (type === "mobile") {
      if (getValues("contact_no")) {
        if (subType === "resend") {
          const res = await resendMobileOTP(getValues("contact_no"));
          if (res?.success) {
            setModalShow2(true);
            setSeconds(90);
            clearErrors();
            setSendError(false);
          } else {
            setSendError(res.message);
          }
        } else {
          const res = await sendMobileOTP(getValues("contact_no"));
          if (res?.success) {
            setModalShow2(true);
            setSeconds(90);
            clearErrors();
            setSendError(false);
          } else {
            setSendError(res.message);
          }
        }
      } else {
        setError("contact_no", {
          message: Errors.phone,
        });
      }
    } else {
      if (getValues("email")) {
        if (RegxExpression.email.test(getValues("email"))) {
          const res = await sendEmailOTP(getValues("email"));

          if (res?.success) {
            setModalShow3(true);
            setSeconds(90);
            clearErrors();
          }
        } else {
          setError("email", {
            message: "Email Invalid",
          });
        }
      } else {
        setError("email", {
          message: Errors.email,
        });
      }
    }
    setOtpLoader(false);
  };

  const verifyOTP = async (type, otp) => {
    const formValues = getValues();
    if (type == "mobile") {
      const res = await verifyMobileOTP(getValues("contact_no"), otp);
      if (res?.success) {
        setMobileVerify(true);

        setModalShow2(false);
        clearErrors();
        setSeconds(0);
      } else {
        setOtpErr(res.message);
      }
    } else {
      const res = await verifyEmailOTP(getValues("email"), otp);
      if (res?.success) {
        setEmailVerify(true);
        setModalShow3(false);
        clearErrors();
        setSeconds(0);
      } else {
        setOtpErr(res.message);
      }
    }
  };

  return (
    <>
      {showLogin ? (
        <LogIn />
      ) : (
        <div className="LogIn">
          <div className="row bg-color me-0 ms-0">
            <div className=" col-lg-7 col-md-9 col-10 p-0">
              <div className="row justify-content-center me-0 ms-0">
                <div className="col-md-6">
                  <div className="back_to_span">
                    <Link to={"/"}>
                      <p>Back to Home</p>
                    </Link>
                  </div>

                  <div className="heading-holder">
                    <h4>Register</h4>
                  </div>

                  <div className="log_in_form">
                    <Form>
                      <div className="login-with-option-holder mb-2">
                        <Form.Group
                          className="mb-2 login-with-heading"
                          controlId=""
                        >
                          <Form.Label>Register With</Form.Label>
                        </Form.Group>
                        <Form.Check
                          inline
                          label="Customer"
                          name="type"
                          type="radio"
                          value="customer"
                          {...register("type", {
                            required: "Select an option",
                          })}
                        />
                        {/* <Form.Check
                          inline
                          label="Vendor"
                          name="type"
                          type="radio"
                          value="vendor"
                          {...register("type", {
                            required: "Select an option",
                          })}
                        /> */}

                        {errors.type && (
                          <div className="invalid-feedback d-block">
                            {errors.type.message}
                          </div>
                        )}
                      </div>
                      <Form.Group className="mb-3" controlId="">
                        <label className="lbbbb">Upload your photo</label>
                        <Form.Control
                          type="file"
                          name="image"
                          placeholder="Upload your photo"
                          {...register("image")}
                          onChange={handleImageChange}
                        />
                        {imagePreview && (
                          <div className="image-preview">
                            <img src={imagePreview} alt="Preview" />
                            <style>
                              {`
        .image-preview img {
          max-width: 100px;
          max-height: 100px;
        }
      `}
                            </style>
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name="first_name"
                          placeholder="Enter Full Name"
                          {...register("first_name", {
                            required: "Full Name is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.first_name,
                            "is-valid": getValues("first_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                      {errors?.first_name && (
                        <sup className="text-danger">
                          {errors?.first_name?.message}
                        </sup>
                      )}
                      {/* <Form.Group className="mb-3" controlId="dob">
                        <DatePicker
                          selected={startDate}
                          onChange={handleDateChange}
                          autoComplete="off"
                          className="form-control"
                          placeholderText="Enter Your Birth Date"
                          maxDate={new Date()}
                        />
                      </Form.Group>

                      {errors.dob && (
                        <sup className="text-danger">{errors.dob.message}</sup>
                      )} */}
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="date"
                          name="dob"
                          placeholder="Enter dob"
                          {...register("dob", {
                            required: "Date Of Birth is required",
                            validate: {
                              notFutureDate: (value) =>
                                new Date(value) <= new Date(todayDate) ||
                                "Date of Birth cannot be in the future",
                            },
                          })}
                          max={todayDate}
                          className={classNames("", {
                            "is-invalid": errors?.dob,
                            "is-valid": getValues("dob"),
                          })}
                        />
                        {errors?.dob && (
                          <sup className="text-danger">
                            {errors?.dob?.message}
                          </sup>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: RegxExpression.email,
                              message: "Invalid email address",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email,
                            "is-valid": getValues("email"),
                          })}
                        />

                        {/* <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <span style={{ color: "green", fontSize: "1.2em" }}>
                            ✔
                          </span>
                        </div> */}
                      </Form.Group>
                      {errors?.email && (
                        <sup className="text-danger">
                          {errors?.email?.message}
                        </sup>
                      )}
                      <Form.Group className="mb-3" controlId="">
                        <div className="password_div">
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.contact_no,
                              "is-valid": getValues("contact_no"),
                            })}
                            type="text"
                            name="contact_no"
                            placeholder="Enter Mobile Number"
                            {...register("contact_no", {
                              required: "Contact Number is required",
                              minLength: {
                                value: 10,
                                message:
                                  "Number should be at least 10 characters",
                              },
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.phone.test(event.key)) {
                                if (event.key !== "Backspace") {
                                  event.preventDefault();
                                }
                              }
                            }}
                            maxLength={10}
                          />

                          <MobileOtpModal
                            show={modalShow2}
                            seconds={seconds}
                            otpErr={otpErr}
                            setSeconds={setSeconds}
                            verifyOTP={verifyOTP}
                            handleSendOtp={() =>
                              handleSendOtp("mobile", "resend")
                            }
                            onHide={() => setModalShow2(false)}
                          />
                          {!mobileVerify && !otpLoader && (
                            <div className="send-otp-text-holder">
                              <p
                                className="telepara mb-0"
                                onClick={() => handleSendOtp("mobile", "send")}
                              >
                                Send OTP
                              </p>
                            </div>
                          )}
                        </div>
                      </Form.Group>
                      {errors?.contact_no && (
                        <sup className="text-danger">
                          {errors?.contact_no?.message}
                        </sup>
                      )}

                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {sendOtpError?.contact_no}
                      </span>
                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password*"
                            name="password"
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                              pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message:
                                  "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                              },
                            })}
                            onChange={(e) => setPassword(e.target.value)}
                          />

                          <div className="eye-icon-holder">
                            <FontAwesomeIcon
                              icon={iconOne}
                              className="eye-icon"
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                            />
                          </div>
                        </div>
                      </Form.Group>
                      {errors?.password && (
                        <sup className="text-danger">
                          {errors?.password?.message}
                        </sup>
                      )}
                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type={showPasswordOne ? "text" : "password"}
                            placeholder="Re-Enter Password*"
                            name="re_password"
                            {...register("re_password", {
                              required: "Re-Password is required",
                            })}
                            onChange={(e) => setPasswordAgain(e.target.value)}
                          />
                          <div className="eye-icon-holder">
                            <FontAwesomeIcon
                              icon={iconTwo}
                              className="eye-icon"
                              onClick={() =>
                                togglePasswordVisibility("reenterPassword")
                              }
                            />
                          </div>
                        </div>
                      </Form.Group>
                      {errors?.re_password && (
                        <sup className="text-danger">
                          {errors?.re_password?.message}
                        </sup>
                      )}
                      {password && (
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                            "match",
                          ]}
                          minLength={8}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => {}}
                          style={{
                            fontSize: "10px",
                            padding: "4px",
                            margin: "0",
                          }}
                        />
                      )}
                    </Form>

                    <div className="btn-holder">
                      <Common_Button
                        className="SubmitBtn"
                        onClick={handleSubmit(onSubmit)}
                        text="Create"
                      />
                    </div>

                    <div className="create_account mb-5">
                      <div className="login_text">
                        <p>
                          Already have an account?
                          <span
                            className="login_text"
                            onClick={handleLoginClick}
                          >
                            LOGIN
                          </span>
                        </p>
                      </div>
                    </div>

                    {/* <div className="log_in_with">
                      <p>or register with social accounts</p>
                    </div> */}

                    {/* <div className="social_img_holder">
                      <img
                        className="social_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/log_in/google.png"
                        }
                        alt="social_img"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-3 p-0">
              <div className="img-section">
                <div className="img-holder">
                  <img
                    className="log_in_img"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/log_in/log_in.png"
                    }
                    alt="log_in_img"
                  />
                </div>
              </div>
            </div>
            <RegisterModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Register_Form;
