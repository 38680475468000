import React, { useEffect, useState, useContext } from "react";
import ProductBanner from "./product-banner/ProductBanner";
import SeclectByCategory from "./Select-by-category/SeclectByCategory";

import { useNavigate } from "react-router-dom";

import { sellerCategory } from "../../utils/apis/SellerApi/SellerApi";
const Product = () => {
  const navigate = useNavigate();

  const [sellerData, setSellerData] = useState();

  const getSeller = async () => {
    const storedSellerId = localStorage.getItem("seller_id");

    if (storedSellerId) {
      const res = await sellerCategory(storedSellerId);

      if (res?.success) {
        setSellerData(res?.data);
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    getSeller();
  }, []);
  return (
    <>
      <ProductBanner sellerData={sellerData} />
      <SeclectByCategory sellerData={sellerData} />
    </>
  );
};

export default Product;
