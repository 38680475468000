import React, { useContext, useEffect, useState } from "react";
import "./Career.css";
import FontAwesome from "react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { getJobs } from "../../utils/apis/common/Common";
import { Context } from "../../utils/context";
import Career_description from "../career/career-description/Career_description";

const Career = () => {
  const [jobs, setJobs] = useState();
  const { getData, IMG_URL } = useContext(Context);
  const getCareerJobs = async () => {
    const res = await getJobs();
    if (res?.success) {
      setJobs(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getCareerJobs();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const career = [
    {
      time: "full",
      icon: '<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />',
      position: "Content Writer",
      address: "Bavdhan,Pune",
      type: "Hybrid",
      experience: "1-3 Years",
    },
    {
      time: "full",
      icon: '<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />',
      position: "Content Writer",
      address: "Bavdhan,Pune",
      type: "Hybrid",
      experience: "1-3 Years",
    },
    {
      time: "full",
      icon: '<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />',
      position: "Content Writer",
      address: "Bavdhan,Pune",
      type: "Hybrid",
      experience: "1-3 Years",
    },
    {
      time: "full",
      icon: '<FontAwesomeIcon icon="fa-solid fa-pen-to-square" />',
      position: "Content Writer",
      address: "Bavdhan,Pune",
      type: "Hybrid",
      experience: "1-3 Years",
    },
  ];

  return (
    <>
      <section className="careermain-sec All-boom-content">
        <div className="container">
          <img
            className="bannerr"
            src={
              process.env.PUBLIC_URL +
              "/assets/Image/earn/Bommerbucks-career.jpg"
            }
          />

          <div className=" text-center">
            <h3>Make Your career with Boomerbucks</h3>
          </div>

          <div className="row mt-5">
            {jobs?.map((data, index) => (
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10 mx-auto mb-4">
                <div className="career-card">
                  <div className="top-div">
                    <p className="jobtime">{data.job_type?.name} time</p>

                    <div className="job-details">
                      <div className="icon-div">
                        <img className="bannerr" src={IMG_URL + data.image} />
                        {/* <FontAwesomeIcon
                          className="iconn"
                          icon="fa-solid fa-pen-to-square"
                        /> */}
                      </div>
                      <h6 className="pos-name">{data.name}</h6>

                      <p className="add-p">
                        <FontAwesomeIcon
                          className="addicc"
                          icon="fa-solid fa-location-dot"
                        />{" "}
                        {data.state.name},{data.city.name}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="">
                      <p className="exppp">
                        <FontAwesomeIcon icon="fa-solid fa-graduation-cap" />{" "}
                        {data.exp_from}-{data.exp_to} years
                      </p>
                    </div>

                    <div>
                      <Link
                        className="applybtn"
                        to={`/career-description/${data.id}`}
                      >
                        View & Apply
                      </Link>

                      {/* <button type="button" className="applybtn">
                        View & Apply
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
