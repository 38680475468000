import React, { useContext, useState, useEffect } from "react";
import "./ProductBanner.css";
import { Context } from "../../../utils/context";
const ProductBanner = ({ sellerData }) => {
  const { IMG_URL, getData } = useContext(Context);
  const [data, setData] = useState({});
  const sellerCategory = async () => {
    const storedSellerId = localStorage.getItem("seller_id");
    try {
      const res = await getData(`/seller/seller-detail/${storedSellerId}`);
      setData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sellerCategory();
  }, [sellerData]);
  return (
    <>
      <section className="product-banner">
        <div className="container">
          <div className="row">
            <div className="d-flex">
              <div className="circle-holder text-center">
                <img
                  className="ecartlogo"
                  src={IMG_URL + data?.logo}
                  alt="ecartlogo"
                />
              </div>
              <div className="name-holder">
                <h1>{data?.store_name}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductBanner;
