import React, { useContext, useEffect, useState } from "react";
import "./ScanMartStore.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Rating } from "react-simple-star-rating";
import TrandBee from "./TrandBee";
import Veggies_Section_Card from "../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import Online_Store from "../../Common-Component/Online_Store/Online_Store";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Veiw_All_Card from "../../Common-Component/Veiw_All_Card/Veiw_All_Card";
import { Category } from "../../../utils/apis/Product/Product";
import { NearSellerGet } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import { sellerCategory } from "../../../utils/apis/SellerApi/SellerApi";
import { Link, useNavigate } from "react-router-dom";
import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";

const ScanMartStore = () => {
  const [rating, setRating] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [likedProducts, setLikedProducts] = useState([]); // State to track liked products
  const [addCartModal, setAddCartModal] = useState(false);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const handleReset = () => {
    // Set the initial value
    setRating(0);
  };

  const handleLike = (index) => {
    setLikedProducts((prevLikedProducts) => {
      const updatedLikedProducts = [...prevLikedProducts];
      updatedLikedProducts[index] = !updatedLikedProducts[index];
      return updatedLikedProducts;
    });
  };
  const StoreDetails = [
    {
      id: 1,
      productname: "Lenovo ",
      weight: "IdeaPad Slim 1",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/near-me/Computer.png",
      detailsLink: "/vendor-page",
    },
    {
      productname: "LG ",
      weight: "6.5 Kg 5 Star Inverter Direct Drive Fully",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image:
        process.env.PUBLIC_URL + "/assets/Image/near-me/washing_machine.png",
    },
    {
      productname: "Samsung ",
      weight: "28L, Convection Microwave Oven with Curd Making",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/near-me/microweb.png",
    },
    {
      productname: "Apple ",
      weight: "iPhone 13 (128GB) - Midnight",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/near-me/phone.png",
    },
    {
      productname: "Samsung ",
      weight: "Galaxy Tab S6 Lite 26.31 cm",
      price: "₹. 00.00",
      offprice: "₹. 00.00",
      image: process.env.PUBLIC_URL + "/assets/Image/near-me/tablet.png",
    },
  ];

  const { IMG_URL, getData, triggerCartUpdate } = useContext(Context);
  const [activeCategory, setActiveCategory] = useState("first");
  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    const res = await Category();
    if (res?.success) {
      setCategory(res?.data);
    }
  };

  const [seller, setSeller] = useState([]);
  const getSeller = async (value) => {
    if (value) {
      await setActiveCategory(value?.name);
    } else {
      await setActiveCategory("first");
    }

    const res = await NearSellerGet(city, value?.id);
    if (res?.success) {
      setSeller(res?.data);
    }
  };

  const [sellerByCategory, setSellerByCategory] = useState([]);
  const getSellerByCat = async (id) => {
    const res = await getData(
      `/without-login/home/seller-product-category?category_id=${id?.id}`
    );
    if (res?.success) {
      setSellerByCategory(res?.data);
    }
  };

  const navigate = useNavigate();
  const handleNext = async (id) => {
    const res = await sellerCategory(id);
    if (res?.success) {
      localStorage.setItem("seller_id", id);
      navigate(`/product`);
    }
  };

  const [city, setCity] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch city name using reverse geocoding
          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
          )
            .then((response) => response.json())
            .then((data) => {
              setCity(data.address.city);
            })
            .catch((error) => {
              console.error("Error fetching city:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    getCategory();
    getSeller();
  }, [city]);

  useEffect(() => {
    triggerCartUpdate();
  }, [addCartModal]);
  useEffect(() => {
    getSellerByCat();
  }, []);
  return (
    <>
      <section className="near-mart-store">
        <div className="container">
          <div className="heading-holder">
            <h1>Near by shops</h1>
          </div>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="flex-row fliter-product-slide">
                  <Swiper
                    className="mySwiper1"
                    breakpoints={{
                      0: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      420: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      486: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                      },
                      992: {
                        slidesPerView: 8,
                        spaceBetween: 10,
                      },
                      1200: {
                        slidesPerView: 10,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    <SwiperSlide className="product-slide">
                      <Nav.Item>
                        <Nav.Link
                          className={activeCategory === "first" ? "active" : ""}
                          onClick={() => getSeller()}
                        >
                          All
                        </Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>

                    {category?.map((value, index) => (
                      <SwiperSlide className="product-slide">
                        <Nav.Item>
                          <Nav.Link
                            className={
                              activeCategory == value?.name ? "active" : ""
                            }
                            onClick={() => getSeller(value)}
                          >
                            {value?.name}
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                    ))}

                    {/* <SwiperSlide className="product-slide">
                      <Nav.Item>
                        <Nav.Link eventKey="Third">Electronic</Nav.Link>
                      </Nav.Item>
                    </SwiperSlide> */}
                  </Swiper>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {seller?.length === 0 ? (
                      <div>No product available</div>
                    ) : (
                      seller?.map((value, index) => (
                        <div className="slider-sec" key={index}>
                          <div className="row">
                            <div className="col-lg-3 col-md-4 mt-5">
                              <Online_Store
                                rating={rating}
                                handleRating={handleRating}
                                image={IMG_URL + value?.logo}
                                heading={value?.store_name}
                                city={value?.city?.name}
                                distanceLocation="5 minutes away from your location"
                              />
                            </div>
                            <div className="col-lg-9 col-md-8 mt-5">
                              <Swiper
                                breakpoints={{
                                  0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                  },
                                  420: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                  },
                                  486: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                  },
                                  768: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 10,
                                  },
                                  992: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 10,
                                  },
                                  1200: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                  },
                                  1400: {
                                    slidesPerView: 4.5,
                                    spaceBetween: 10,
                                  },
                                  1900: {
                                    slidesPerView: 4.5,
                                    spaceBetween: 10,
                                  },
                                }}
                                navigation={true}
                                modules={[Navigation, Pagination]}
                                pagination={{
                                  dynamicBullets: true,
                                }}
                                className="mySwiper"
                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                              >
                                {value?.user?.products.length === 0 ? (
                                  <SwiperSlide>
                                    No product Available
                                  </SwiperSlide>
                                ) : (
                                  value?.user?.products.map((item, index) => (
                                    <SwiperSlide
                                      key={`${index}-${item.detailsLink}`}
                                    >
                                      <Veggies_Section_Card
                                        image={IMG_URL + item?.image1}
                                        vegetableName={item?.name}
                                        price={
                                          item?.product_variants[0]?.s_price
                                            ? item?.product_variants[0]?.s_price
                                            : item?.product_multiple_variants[0]
                                                ?.s_price
                                        }
                                        offprice={
                                          item?.product_variants[0]?.mrp
                                            ? item?.product_variants[0]?.mrp
                                            : item?.product_multiple_variants[0]
                                                ?.mrp
                                        }
                                        product={item}
                                        setAddCartModal={setAddCartModal}
                                        variant={item?.product_variants[0]}
                                        m_variant={
                                          item?.product_multiple_variants[0]
                                        }
                                      />
                                    </SwiperSlide>
                                  ))
                                )}
                                <SwiperSlide>
                                  <div
                                    onClick={() => handleNext(value?.user_id)}
                                  >
                                    <Veiw_All_Card />
                                  </div>
                                </SwiperSlide>
                              </Swiper>

                              <div className="silder-btn">
                                <div
                                  className="back-btn"
                                  onClick={() => handleMainSliderClick("prev")}
                                ></div>
                                <div
                                  className="next-btn"
                                  onClick={() => handleMainSliderClick("next")}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                  <Tab.Pane eventKey="Third">Third tab content</Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <SupermarketModal
            show={addCartModal}
            onHide={() => setAddCartModal(false)}
          />
        </div>
      </section>
    </>
  );
};

export default ScanMartStore;
