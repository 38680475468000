import React, { useContext, useEffect, useState } from "react";
import "./Wishlist.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardFooter, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import SupermarketModal from "../Common_modal/Supermarket-modal/SupermarketModal";
import Product_Card from "../Common-Component/Product_Card/Product_Card";
import Common_Button from "../Common-Component/Common_Button/Common_Button";

import { ShimmerPostItem } from "react-shimmer-effects";
import { getWishlist, addWishlist } from "../../utils/apis/Wishlist/Wishlist";
import { AddToCart } from "../../utils/apis/addToCart/addToCart";
import { Context } from "../../utils/context";
library.add(fas);
const Wishlist = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [activeHearts, setActiveHearts] = useState([]);
  const handleHeartClick = (index) => {
    const updatedHearts = [...activeHearts];
    updatedHearts[index] = !updatedHearts[index];
    setActiveHearts(updatedHearts);
  };

  const [wishlistProducts, setWishlistProduct] = useState([]);

  const navigate = useNavigate();
  const {
    IMG_URL,
    shimmerLoader,
    setShimmerLoader,
    wishlistData,
    wishlistContext,
  } = useContext(Context);

  const handleWishCart = async () => {
    await Promise.all(
      wishlistData?.map(async (value) => {
        await AddToCart({
          product_id: value?.product_id,
          m_variant_id: value?.m_variant_id,
          variant_id: value?.variant_id,
        });
        await addWishlist(value?.product_id);
      })
    );

    setModalShow(true);
    setTimeout(() => {
      setModalShow(false);
      navigate("/product-cart");
    }, 3000);
  };

  const getWishlist = async () => {
    setShimmerLoader(true);

    await setWishlistProduct(wishlistData);

    setShimmerLoader(false);
  };

  useEffect(() => {
    getWishlist();
  }, [wishlistData]);

  return (
    <>
      {wishlistProducts.length > 0 ? (
        <section className="wishlist">
          <Container>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Wishlist</Breadcrumb.Item>
              </Breadcrumb>

              {shimmerLoader ? (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6}>
                  <ShimmerPostItem card title cta imageHeight={180} />
                </Col>
              ) : (
                wishlistProducts.map((value, index) => (
                  <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                    <Product_Card
                      image={IMG_URL + value?.product?.image1}
                      product_id={value?.product_id}
                      variant_id={value?.product?.product_variants[0]?.id}
                      m_variant_id={
                        value?.product?.product_multiple_mains?.[0]
                          ?.product_multiple_variants[0]?.id
                      }
                      text={value?.product?.name}
                      subText={value?.product?.short_description}
                      price={
                        value?.product?.product_variants?.[0]?.s_price
                          ? value?.product?.product_variants?.[0]?.s_price
                          : value?.product?.product_multiple_variants?.[0]
                              ?.s_price
                      }
                    />
                  </Col>
                ))
              )}
              <div className="view-all-product  mt-5">
                <p onClick={handleWishCart}>Add all product into cart</p>
              </div>
            </Row>
          </Container>
        </section>
      ) : (
        <section className="Empty_Cart">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-5 col-md-6 col-sm-7 col-10">
                <div className="shop-now-content">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/Cash_On_Delivery_Process/basket.png"
                      }
                      className="basket_img"
                    />
                  </div>
                  <div className="heading_holder mt-3">
                    <h5>Your Wishlist is Empty</h5>
                    <p> Explore more and shortlist some items</p>
                    <div className="main-btn-div">
                      <Link to={"/"}>
                        <Common_Button
                          className=" back-btn"
                          text=" Start Shopping"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <SupermarketModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Wishlist;
