import React from "react";

function Popular_Product_Card({
  content,
  image,
  imgClassName,
  className,
  textClassName,
  alt,
}) {
  return (
    <>
      <div className={className}>
        <img src={image} className={imgClassName} alt={alt} />
        <div className="text-holder">
          <p className={textClassName}>{content}</p>
        </div>
        <div className="bg-overlay"></div>
      </div>
    </>
  );
}

export default Popular_Product_Card;
