import React, { useState, useEffect } from "react";
import "./Coupon_Card.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

function Coupon_Card({
  codeText,
  timer,
  imageUrl,
  type,
  discount,
  total_amount,
}) {
  const formatTime = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeText);
    alert("Coupon code copied to clipboard!");
  };

  return (
    <section className="Coupon_Card">
      <div className="card-holder">
        <div className="row">
          <div className="col-md-5 col-sm-5 col-5 border-right-holder">
            <div className="img-holder">
              <img className="Coupon-img" src={imageUrl} alt="Coupon-img" />
            </div>
          </div>

          <div className="col-md-7 col-sm-7 col-7">
            <div className="coupon-text-holder">
              <div>
                <div className="text-holder">
                  <div className="heading-holder">
                    <h3>Code</h3>
                  </div>
                  <div className="code-holder">
                    <div>
                      <p>{codeText}</p>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        className="copy-icon"
                        icon={faCopy}
                        onClick={copyToClipboard}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-holder">
                  <div className="heading-holder">
                    <h3>Expiry</h3>
                  </div>
                  <div className="code-holder">
                    <div>
                      <p>{timer}</p>
                    </div>
                  </div>
                </div>

                <div className="text-holder">
                  <div className="code-holder">
                    {/* <div>
                      <p>View Products</p>
                    </div> */}
                    <div>
                      {/* <FontAwesomeIcon
                        className="copy-icon"
                        icon={faAngleRight}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Coupon-discount-holder">
        <div className="text-holder">
          <h3>
            {type === "percentage"
              ? `${discount} % Off`
              : `${total_amount} rs Off`}
          </h3>

          {/* <p>upto ₹200</p> */}
        </div>
      </div>
    </section>
  );
}

export default Coupon_Card;
