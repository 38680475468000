import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

function Please_add_address(props) {
  const { message, onHide } = props; // Extracting 'message' and 'onHide' props

  useEffect(() => {
    // Set a timeout to close the modal after 2 seconds
    const timer = setTimeout(() => {
      onHide(); // Call the onHide function to close the modal
    }, 2000);

    // Cleanup the timer if the component is unmounted before 2 seconds
    return () => clearTimeout(timer);
  }, [onHide]);

  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal"
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="Add_Address_icon_holder ">
              <FontAwesomeIcon
                className={"Add_Address_home_icon"}
                icon={faHouse}
              />
            </div>
            <p>{"Please Add Address"}</p> {/* Displaying the 'message' prop */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Please_add_address;
