import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./Birthday_Modal.css";
import Membership_card from "../../Common-Component/Membership_card/Membership_card";
import Membership__Modal_card from "../../Common-Component/Membership__Modal_card/Membership__Modal_card";
import { Context } from "../../../utils/context";
import { UserGet } from "../../../utils/apis/myAccount/myAccount";

function Birthday_Modal(props) {
  const { setUserData, userdata } = useContext(Context);

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <div className="Successfull_Modal_sec ">
        <Modal
          className="Successfull_Modal Birthday_Modal"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="content-holder">
              <div className="row me-0 ms-0">
                <div className="col-md-6">
                  <div className="Birthday-video-holder">
                    <video className="video-holder" controls autoPlay loop mute>
                      <source
                        className="videosec"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Coupon/free-happy-birthday-icegif-ezgif.com-gif-to-mp4-converter.mp4"
                        }
                      ></source>
                    </video>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Heading-text-holder">
                    <h3>{userdata.first_name}</h3>
                  </div>

                  <div className="Wish-text-holder">
                    <p>
                      Happy Birthday, {userdata.first_name}! Take an EXTRA 15%
                      off – Ashley Stewart
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Birthday_Modal;
