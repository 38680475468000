import React, { useState, useEffect, useContext } from "react";
import "./Product_Cart.css";
import {
  Breadcrumb,
  Button,
  Form,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import Cart from "./Cart/Cart";
import { Context } from "../../utils/context";

const Product_Cart = () => {
  const { signin } = useContext(Context);

  const [amount, setAmount] = useState(0);
  const [coupon, setCoupon] = useState("");

  const [discount, setDiscount] = useState(0);
  return (
    <>
      <div className="Product_Cart">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={"/product-cart"}>Cart</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Cart
            type={"cart"}
            amount={amount}
            setAmount={setAmount}
            discount={discount}
            setDiscount={setDiscount}
            coupon={coupon}
            setCoupon={setCoupon}
          />
          <div className="row justify-content-end">
            <div className="col-xxl-3 col-xl-3 col-lg-4">
              <div className="button-holder text-end">
                {/* {signin && */}
                <Link to={signin ? "/product-cart-details" : "/login"}>
                  <Button className="procheckout_btn" type="button">
                    Proceed to Checkout
                  </Button>
                </Link>
                {/* } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product_Cart;
