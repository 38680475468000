import React, { useEffect } from "react";
import AboutBanner from "./about-banner/AboutBanner";
import OurStory from "./our-story/OurStory";
import WhyChoose from "./why-choose/WhyChoose";
import HappyCustomer from "./happy-customer/HappyCustomer";
import CounterSec from "./Counter-sec/CounterSec";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <AboutBanner />
      <OurStory />
      <WhyChoose />
      <HappyCustomer />
      <CounterSec />
    </>
  );
};

export default AboutUs;
