import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./CongratsSuccessmodal.css";
import CongratSuccess from "../../Animation/congratSuccess/CongratSuccess.js";
function CongratsSuccessmodal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} className="congratsModal" centered>
      <Modal.Body className="text-center">
        <CongratSuccess />
        <h4>CONGRATULATIONS!</h4>
        <p>You have completed the process successfully!</p>
        <div className="alert alert-light" role="alert">
          Your request to become a Seller with us, is under review. If you have
          any queries, please reach out us at{" "}
          <a href="">boomerbucks.in@outlook.com</a> /{" "}
          <a href="tel:+91 5483580687">+91 5483580687</a>.
        </div>
        <p>
          Process would take 7 - 8 days to review.
          <br />
          We are eager to see you with us!
        </p>

        <Button variant="primary" className="dismissbtn" onClick={handleClose}>
          Dismiss
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default CongratsSuccessmodal;
