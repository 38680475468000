import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./components/Common_css/Swiper_Slider/Swiper_Slider.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import HomePage from "./components/HomePage/HomePage";
import Notification from "./components/Notification/Notification";
import Product from "./components/product/Product";
import VendorPage from "./components/VendorPage/VendorPage";
import VendorProduct from "./components/VendorProduct/VendorProduct";
import LogIn from "./components/Log-in/LogIn";
import AboutUs from "./components/About-us/AboutUs";
import ContactUs from "./components/contact-us/ContactUs";
import Blog from "./components/Blog/Blog";
import BlogDetails from "./components/Blog/Blog-details/BlogDetails";
import BecomeASeller from "./components/become-a-seller/BecomeASeller";
import NearMe from "./components/Near-me/NearMe";
import Product_Cart from "./components/Product_Cart/Product_Cart";
import CheckoutProcess from "./components/Product_Cart/CheckoutProcess/CheckoutProcess";
import Shop_Now from "./components/Product_Cart/Shop_Now/Shop_Now";
import EmptyWishlist from "./components/Wishlist/Empty-wishlist/EmptyWishlist";
import Wishlist from "./components/Wishlist/Wishlist";
import MyAccount from "./components/MyAccount/MyAccount";
import OrderHistory from "./components/MyAccount/OrderHistory/OrderHistory";
import AccountInfo from "./components/MyAccount/AccountInfo/AccountInfo";
import ChangePassword from "./components/MyAccount/ChangePassword/ChangePassword";
import Forget_Password from "./components/Log-in/Forget_Password/Forget_Password";
import Register_Form from "./components/Log-in/Register_Form/Register_Form";
import ViewAllProduct from "./components/product/View-all-product/ViewAllProduct";
import Faq from "./components/Faq/Faq";
import OrderReturn from "./components/MyAccount/Account/Order-return/OrderReturn";
import OrderReturnHistory from "./components/MyAccount/OrderHistory/order-return-history/OrderReturnHistory";
import TermsCondition from "./components/TermsCondition/TermsCondition";
import Account_Address from "./components/MyAccount/Account_Address/Account_Address";
import My_Coupons from "./components/MyAccount/My_Coupons/My_Coupons";
import All_Categories from "./components/All_Categories/All_Categories";
import Privacy_Policy from "./components/Privacy_Policy/Privacy_Policy";
import Membership from "./components/Membership/Membership";
import { Context } from "./utils/context";
import Wallet from "./components/Wallet/Wallet";
import WalletPayment from "./components/Wallet/wallet-payment/WalletPayment";
import WalletTranctionProfile from "./components/wallet-tranction/WalletTranctionProfile";
import Birthday_Modal from "./components/Common_modal/Birthday_Modal/Birthday_Modal";
import Newly_launch from "./components/Common_modal/newly_launch/Newly_launch";
import Earn_With_Us from "./components/Earn_With_Us/Earn_With_Us";
import SelectFromCategories from "./components/SelectFromCategories/SelectFromCategories";
// import Coupan from "./components/Coupan/Coupan";
import AddAddress from "./components/MyAccount/AddAddress/AddAddress";
import Sell_on_Boomerbucks from "./components/Common-Component/All_content/Sell_on_Boomerbucks";
import Promote_product from "./components/Common-Component/All_content/Promote_product";
import Become_franchise from "./components/Common-Component/All_content/Become_franchise";
import Become_delivery from "./components/Common-Component/All_content/Become_delivery";
import Become_distributor from "./components/Common-Component/All_content/Become_distributor";
import Career from "./components/career/Career";
import Career_description from "./components/career/career-description/Career_description";
import { UserGet } from "./utils/apis/myAccount/myAccount";
import Cookies from "js-cookie";

function App() {
  const location = useLocation();
  const { userdata, setUserData, signin } = useContext(Context);
  const isLoginPage = location.pathname === "/login";
  const [modalShow, setModalShow] = useState(false);
  const [modalShowlaunch, setModalShowlaunch] = useState(false);

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (signin == true) {
      if (Cookies.get("boomer_bucks_advertise")) {
        // if (location.pathname === "/") {
        //   const newlyLaunchModalTimer = setTimeout(() => {
        //     setModalShowlaunch(true);
        //   }, 2000);
        //   return () => clearTimeout(newlyLaunchModalTimer);
        // }
      } else {
        if (location.pathname === "/") {
          const newlyLaunchModalTimer = setTimeout(() => {
            setModalShowlaunch(true);
          }, 2000);
          Cookies.set("boomer_bucks_advertise", newlyLaunchModalTimer);

          return () => clearTimeout(newlyLaunchModalTimer);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    const checkBirthday = () => {
      if (userdata.dob) {
        const today = new Date();
        const userDOB = new Date(userdata.dob);
        return (
          today.getDate() === userDOB.getDate() &&
          today.getMonth() === userDOB.getMonth()
        );
      }
      return false;
    };

    if (checkBirthday()) {
      const birthdayModalTimer = setTimeout(() => {
        setModalShow(true);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(birthdayModalTimer);
    }
  }, [userdata.dob]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <ScrollToTop />
      <div>
        {!isLoginPage && <Header />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/near-me" element={<NearMe />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blog-details/:id" element={<BlogDetails />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/become-seller" element={<BecomeASeller />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/vendor-page" element={<VendorPage />} />
          <Route path="/product-cart" element={<Product_Cart />} />
          <Route path="/product-cart-details" element={<CheckoutProcess />} />
          <Route path="/shop-now" element={<Shop_Now />} />
          <Route path="/empty-wislist" element={<EmptyWishlist />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/account-address" element={<Account_Address />} />
          <Route path="/all-categories" element={<All_Categories />} />
          <Route path="/my-coupons" element={<My_Coupons />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/account-information" element={<AccountInfo />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/order-return/:orderId" element={<OrderReturn />} />
          <Route
            path="/order-return-history"
            element={<OrderReturnHistory />}
          />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/wallet-payment" element={<WalletPayment />} />
          <Route
            path="/wallet-transaction"
            element={<WalletTranctionProfile />}
          />
          <Route path="/address" element={<AddAddress />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/view-all-product" element={<ViewAllProduct />} />
          <Route path="/product" element={<Product />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/earn-with-us" element={<Earn_With_Us />} />
          <Route
            path="/select-from-category/:id"
            element={<SelectFromCategories />}
          />
          <Route path="/information" element={<AccountInfo />} />
          {/* <Route path="/coupan" element={<Coupan />} /> */}
          {/* <Route path="/forget-password" element={<Forget_Password />} /> */}
          {/* <Route path="/register-form" element={<Register_Form />} /> */}
          {/* <Route path="/product-detail" element={<VendorProduct />} /> */}
          {/* <Route path="/notify" element={<Notification />} /> */}

          <Route
            path="/sell-on-boomerbucks"
            element={<Sell_on_Boomerbucks />}
          />
          <Route path="/promote-product" element={<Promote_product />} />
          <Route path="/become-franchise" element={<Become_franchise />} />
          <Route path="/become-delivery" element={<Become_delivery />} />
          <Route path="/become-distributor" element={<Become_distributor />} />
          <Route path="/career" element={<Career />} />
          <Route
            path="/career-description/:id"
            element={<Career_description />}
          />
        </Routes>
        {!isLoginPage && <Footer />}
      </div>

      <Birthday_Modal show={modalShow} onHide={() => setModalShow(false)} />
      <Newly_launch
        show={modalShowlaunch}
        onHide={() => setModalShowlaunch(false)}
      />
    </>
  );
}

export default App;
