import React, { useEffect } from "react";
import "../All_content/All_cont.css";
import { Link } from "react-router-dom";
import Become_inquiry_form from "./Become_inquiry_form";

const Become_delivery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="All-boom-content">
        <div className="container">
          <h1 className="boomtitle">Become Delivery Partner</h1>
          <div className="row">
            <div className="col-lg-8">
              <img
                className="bannerr"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Image/earn/Become_delievery_partner.jpg"
                }
              />
              <div className="cont-sec">
                <p>
                  Becoming a delivery partner with Boomerbucks Online Shopping
                  Company is a great opportunity to expand your business
                  operations and earn additional income. Here’s a detailed guide
                  on how to become a delivery partner and successfully integrate
                  with Boomerbucks:
                </p>

                <h6>Steps to Become a Delivery Partner</h6>

                <ol>
                  <ul>
                    <li className="listmain">
                      Application Process
                      <ul>
                        <li>
                          Visit the Boomerbucks Website: Navigate to the section
                          dedicated to becoming a delivery partner.
                        </li>
                        <li>
                          Fill Out the Application Form: Provide all the
                          necessary details such as personal information,
                          contact details, vehicle information, and any previous
                          delivery experience.
                        </li>
                        <li>
                          Submit Required Documents: Upload necessary documents
                          like a valid driver’s license, vehicle registration,
                          insurance papers, and any other required
                          certifications.
                        </li>
                      </ul>
                    </li>

                    <li className="listmain">
                      Screening and Approval
                      <ul>
                        <li>
                          Background Check: Boomerbucks may conduct a background
                          check to ensure the safety and reliability of their
                          delivery partners.
                        </li>

                        <li>
                          Vehicle Inspection: Your vehicle may need to pass an
                          inspection to ensure it meets Boomerbucks&#39;
                          standards.
                        </li>

                        <li>
                          Approval Notification: Once approved, you will receive
                          a notification from Boomerbucks with further
                          instructions.
                        </li>
                      </ul>
                    </li>

                    <li className="listmain">
                      Onboarding and Training
                      <ul>
                        <li>
                          Orientation Session: Attend an orientation session
                          where you will learn about Boomerbucks&#39; delivery
                          processes, expectations, and use of their delivery
                          app.
                        </li>

                        <li>
                          Training: Complete any required training modules to
                          familiarize yourself with Boomerbucks&#39; policies,
                          procedures, and customer service standards.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ol>

                <h6>Setting Up for Deliveries</h6>

                <ol>
                  <li className="listmain">
                    Download the Delivery App
                    <ul>
                      <li>
                        App Installation: Download the Boomerbucks delivery app
                        on your smartphone.
                      </li>
                      <li>
                        Login Credentials: Use the login credentials provided
                        during the onboarding process to access the app.
                      </li>
                    </ul>
                  </li>

                  <li className="listmain">
                    Profile Setup
                    <ul>
                      <li>
                        Complete Your Profile: Fill out your profile with
                        accurate and up-to-date information.
                      </li>
                      <li>
                        Set Availability: Indicate your available hours and
                        preferred delivery areas.
                      </li>
                    </ul>
                  </li>
                </ol>

                <h6>Performing Deliveries</h6>
                <ol>
                  <li className="listmain">
                    Accepting Orders
                    <ul>
                      <li>
                        Receive Notifications: Get notifications for delivery
                        requests in your area.
                      </li>
                      <li>
                        Accept Orders: Accept delivery orders through the app
                        based on your availability.
                      </li>
                    </ul>
                  </li>

                  <li className="listmain">
                    Pick Up Orders
                    <ul>
                      <li>
                        Order Details: Review order details including the pickup
                        location and customer information.
                      </li>
                      <li>
                        Pick Up Process: Follow the instructions to pick up the
                        order from the designated location.
                      </li>
                    </ul>
                  </li>

                  <li className="listmain">
                    Delivering Orders
                    <ul>
                      <li>
                        Navigate Efficiently: Use the app’s navigation feature
                        to find the best route to the customer’s location.
                      </li>
                      <li>
                        Timely Delivery: Ensure timely delivery of orders while
                        maintaining the quality and condition of the items.
                      </li>
                      <li>
                        Customer Interaction: Provide excellent customer service
                        by being polite, professional, and responsive.
                      </li>
                    </ul>
                  </li>
                </ol>

                <h6>Benefits of Becoming a Delivery Partner</h6>
                <ol>
                  <li className="listmain">
                    Flexible Working Hours
                    <ul>
                      <li>
                        Set Your Schedule: Choose your working hours and manage
                        your time effectively.
                      </li>
                      <li>
                        Part-Time or Full-Time: Work part-time to earn extra
                        income or full-time as a primary job.
                      </li>
                    </ul>
                  </li>

                  <li className="listmain">
                    Efficiency and Reliability
                    <ul>
                      <li>
                        Time Management: Manage your time effectively to
                        maximize the number of deliveries.
                      </li>
                      <li>
                        Reliability: Be reliable and consistent to build a
                        positive reputation and increase your earning potential.
                      </li>
                    </ul>
                  </li>

                  <li className="listmain">
                    Safety and Maintenance
                    <ul>
                      <li>
                        Vehicle Maintenance: Keep your vehicle well-maintained
                        to ensure safe and efficient deliveries.
                      </li>
                      <li>
                        Follow Safety Guidelines: Adhere to all safety
                        guidelines to protect yourself and the items you are
                        delivering.
                      </li>
                    </ul>
                  </li>
                </ol>

                <p>
                  By following these steps and guidelines, you can successfully
                  become a delivery partner with Boomerbucks Online Shopping
                  Company, enjoy flexible working hours, and earn a competitive
                  income while providing excellent service to customers.
                </p>

                <div className="number-text-holder">
                  <h3 className="heading-holder">
                    Help Desk/Inquiry:{" "}
                    <span className="number-text">+054 835 80687 </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-8 mx-auto">
              <Become_inquiry_form />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Become_delivery;
