import React, { useEffect } from "react";
import Account from "./Account/Account";

function MyAccount() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <Account />
    </>
  );
}

export default MyAccount;
