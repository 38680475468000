import React, { useContext, useEffect, useState } from "react";
import "./All_cont.css";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useForm } from "react-hook-form";
import { Context } from "../../../utils/context";
import { postInquiryForm } from "../../../utils/apis/common/Common";
import Common_Button from "../Common_Button/Common_Button";
import { useLocation, useParams } from "react-router-dom";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";

const Become_inquiry_form = () => {
  const { paramName } = useParams();

  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);
  const { getData, IMG_URL, Select2Data } = useContext(Context);

  const location = useLocation();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await postInquiryForm(data);
    if (res?.success) {
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        reset();
      }, 3000);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    if (location.pathname === "/become-delivery") {
      setValue("type", "4");
    }
    if (location.pathname === "/sell-on-boomerbucks") {
      setValue("type", "1");
    }
    if (location.pathname === "/promote-product") {
      setValue("type", "2");
    }
    if (location.pathname === "/become-franchise") {
      setValue("type", "3");
    }
    if (location.pathname === "/become-distributor") {
      setValue("type", "5");
    }
  }, [location.pathname, setValue]);
  return (
    <>
      <div className="Become_inquiry_form">
        <div className="text-center">
          <h5>-- Inquire Now --</h5>
        </div>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              name="first_name"
              placeholder="Enter First Name"
              {...register("first_name", {
                required: "First Name is required",
              })}
              className={classNames("", {
                "is-invalid": errors?.first_name,
              })}
              onKeyDown={(event) => {
                if (!/[A-Z-a-z ]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            {errors.first_name && (
              <div className="invalid-feedback">
                {errors.first_name.message}
              </div>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              name="last_name"
              placeholder="Enter Last Name"
              {...register("last_name", {
                required: "Last Name is required",
              })}
              className={classNames("", {
                "is-invalid": errors?.last_name,
              })}
              onKeyDown={(event) => {
                if (!/[A-Z-a-z ]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            {errors.last_name && (
              <div className="invalid-feedback">{errors.last_name.message}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1 ">
            <Form.Control
              type="text"
              name="email"
              placeholder="E-mail Address"
              {...register("email", {
                required: "Email Id required",
                pattern: {
                  value:
                    /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                  message: "Email Id Invalid",
                },
              })}
              className={classNames("", {
                "is-invalid": errors?.email,
              })}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email.message}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              name="contact_no"
              placeholder="Contact No."
              {...register("contact_no", {
                required: "Contact number is required",
                validate: {
                  isTenDigits: (value) =>
                    (value && value.length === 10) ||
                    "Contact number must be 10 digits",
                  isNumeric: (value) =>
                    /^\d+$/.test(value) || "Contact number must be numeric",
                },
              })}
              className={classNames("", {
                "is-invalid": errors?.contact_no,
                "is-valid":
                  !errors?.contact_no && getValues("contact_no")?.length === 10,
              })}
              onKeyDown={(event) => {
                const { key } = event;
                if (
                  !/[0-9]/.test(key) &&
                  key !== "Backspace" &&
                  key !== "Tab"
                ) {
                  event.preventDefault();
                }
                if (
                  event.target.value?.length >= 10 &&
                  key !== "Backspace" &&
                  key !== "Tab"
                ) {
                  event.preventDefault();
                }
              }}
            />
            {errors.contact_no && (
              <div className="invalid-feedback">
                {errors.contact_no.message}
              </div>
            )}
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Inquire For</Form.Label>
            <Form.Select
              aria-label="Default select example"
              {...register("type")}
              disabled
            >
              <option>Select your choice</option>
              <option value="1">Sell on Boomerbucks</option>
              <option value="2">Promote your products</option>
              <option value="3">Become Franchise</option>
              <option value="4">Become Delivery Partner</option>
              <option value="5">Become Distributor</option>
            </Form.Select>
          </Form.Group>

          <div className="btn-holder">
            <Common_Button
              className="SubmitBtn"
              text="Send Inquiry"
              padding="7px 20px"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </Form>
      </div>
      <Successfull_Modal
        show={successModal}
        onHide={() => setSuccessModal(false)}
        message={"Success...! Inquiry Submitted Successfully"}
      />
    </>
  );
};

export default Become_inquiry_form;
