import React, { useState, useEffect, useContext } from "react";
import "./CounterSec.css";
import CountUp from "react-countup";
import {
  getCategories,
  getProductCount,
  getSupplierCount,
} from "../../../utils/apis/common/Common";
import { Context } from "../../../utils/context";
const CounterSec = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [product, setProduct] = useState();
  const [category, setCategory] = useState();
  const [suppliers, setSuppliers] = useState();

  const getProjectTwo = async () => {
    const res = await getProductCount();
    if (res?.success) {
      setProduct(res?.data);
    }
  };
  const getCategory = async () => {
    const res = await getCategories();
    if (res?.success) {
      setCategory(res?.data);
    }
  };

  const getSuppliers = async () => {
    const res = await getSupplierCount();
    if (res?.success) {
      setSuppliers(res?.data);
    }
  };

  useEffect(() => {
    getProjectTwo();
    getCategory();
    getSuppliers();
  }, []);
  return (
    <>
      <section className="counter-sec">
        <div className="container">
          <div className="bg-image">
            <div className="imag-overlay"></div>
            <div className="row justify-content-center">
              <div className="heading-holder text-center mb-5">
                <h2>
                  Track real-time sales and customer engagement with our dynamic
                  e-commerce website counter section
                </h2>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4">
                <div className="border-line">
                  <div className="total-holder text-center">
                    <h4>
                      <CountUp
                        start={0}
                        end={product?.count}
                        duration={4}
                        decimals={0}
                      />{" "}
                      +
                    </h4>
                    <p>Products</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4">
                <div className="border-line">
                  <div className="total-holder text-center">
                    <h4>
                      <CountUp
                        start={0}
                        end={suppliers?.count}
                        duration={4}
                        decimals={0}
                      />{" "}
                      +
                    </h4>
                    <p>Suppliers</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4">
                <div className="total-holder text-center">
                  <h4>
                    <CountUp
                      start={0}
                      end={category?.count}
                      duration={4}
                      decimals={0}
                    />{" "}
                    +
                  </h4>
                  <p>Categories</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CounterSec;
