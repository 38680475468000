import React, { useState, useEffect, useContext } from "react";
import "./HappyCustomer.css";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { getHappyCustomers } from "../../../utils/apis/common/Common";
import parse from "html-react-parser";

library.add(fas);
const HappyCustomer = () => {
  const [happyCustomers, setHappyCustomers] = useState();
  const { getData, IMG_URL } = useContext(Context);
  const getProject = async () => {
    const res = await getHappyCustomers();
    if (res?.success) {
      setHappyCustomers(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const clientReviews = [
    {
      name: "Kristina",
      review:
        "Effortless shopping experience with a wide range of products and lightning-fast delivery - this app makes online shopping a breeze!.",
      rating: 5, // Assuming all ratings are 5 stars,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
    },
    {
      name: "Rose Merry",
      review:
        "Sleek interface, intuitive navigation, and secure transactions - this e-commerce app sets the standard for user-friendly online shopping.",
      rating: 5, // Assuming all ratings are 5 stars,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
    },
    {
      name: "John Doe",
      review:
        "From fashion to electronics, this app has it all! With seamless browsing and hassle-free checkout, it's my go-to for all my online purchases.",
      rating: 5, // Assuming all ratings are 5 stars,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
    },
    {
      name: "Kristina",
      review:
        "Effortless shopping experience with a wide range of products and lightning-fast delivery - this app makes online shopping a breeze!.",
      rating: 2, // Assuming all ratings are 5 stars,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
    },
    {
      name: "Rose Merry",
      review:
        "Sleek interface, intuitive navigation, and secure transactions - this e-commerce app sets the standard for user-friendly online shopping.",
      rating: 5, // Assuming all ratings are 5 stars,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
    },
    {
      name: "John Doe",
      review:
        "From fashion to electronics, this app has it all! With seamless browsing and hassle-free checkout, it's my go-to for all my online purchases.",
      rating: 5, // Assuming all ratings are 5 stars,
      imgSrc: process.env.PUBLIC_URL + "/assets/Image/about/client-img.png",
    },
  ];
  return (
    <>
      <section className="happy-clients">
        <div className="container">
          <div className="row">
            <div class="text-center mb-4">
              <h3 class="hot-title">Happy Customers</h3>
              <div class="title-border"></div>
            </div>
            <div className="happy-cuto-sec">
              <Swiper
                spaceBetween={30}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                autoplay={{ delay: 300 }}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                className="mySwiper  happy-clients-swiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  420: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  486: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                {happyCustomers?.map((review, index) => (
                  <SwiperSlide key={index}>
                    <div className="HappyCustomer_card">
                      <div className="client-div mt-3 mb-5">
                        <Row>
                          <Col lg={3} md={4} sm={4} xs={4}>
                            <div className="main-client">
                              <img
                                className="client-img"
                                src={IMG_URL + review?.image}
                                alt=""
                              />
                            </div>
                          </Col>
                          <Col lg={9} md={8} sm={8} xs={8}>
                            <h5 className="client-name">{review?.name}</h5>
                          </Col>{" "}
                          {/* <Col lg={9} md={8} sm={8} xs={8}>
                        <h5 className="client-name">{review?.city}</h5>
                      </Col> */}
                        </Row>
                        <div className="mt-3">
                          <p className="client-review">
                            {parse(review?.description)}
                          </p>{" "}
                          <br />
                          <div>
                            <p className="client-review">
                              {" "}
                              <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                              <span> {review?.city}</span>
                            </p>
                          </div>
                          <div className="d-flex">
                            {Array.from({ length: review?.star || 0 }).map(
                              (_, i) => (
                                <div key={i}>
                                  <FontAwesomeIcon
                                    icon={["fas", "star"]}
                                    className="star-icon mt-3"
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div class="silder-btn">
                <div
                  className="back-btn"
                  onClick={() => handleMainSliderClick("prev")}
                ></div>
                <div
                  className="next-btn"
                  onClick={() => handleMainSliderClick("next")}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HappyCustomer;
