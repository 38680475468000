import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

// export const sendMobileOTP = async (contact_no) => {
//   try {
//     const res = await postData(`/seller/verify-otp/mobile-otp-send`, {
//       contact_no,
//     });

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const postContactUs = async (data) => {
  try {
    return await postData(`/contact/save`, data);
  } catch (error) {
    console.log(error);
  }
};

export const sellerRegistrationForm = async (data) => {
  try {
    return await postData(`/seller/add`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getAllBlog = async () => {
  try {
    return await getData(`/blog/show/`);
  } catch (error) {
    console.log(error);
  }
};

export const getAllBlogVideo = async () => {
  try {
    return await getData(`/blog/video`);
  } catch (error) {
    console.log(error);
  }
};

export const Webheader = async () => {
  try {
    return await getData(`/without-login/home/web-header`);
  } catch (error) {
    console.log(error);
  }
};

export const AppSetup = async () => {
  try {
    return await getData(`/without-login/home/app-setup`);
  } catch (error) {
    console.log(error);
  }
};

export const getCount = async () => {
  try {
    return await getData(`/about/counter`);
  } catch (error) {
    console.log(error);
  }
};

export const bannerLelo = async () => {
  try {
    return await getData(`/without-login/about-us/about-banner/allaboutbanner`);
  } catch (error) {
    console.log(error);
  }
};

export const allourstory = async () => {
  try {
    return await getData(`/without-login/about-us/our-story/allourstory`);
  } catch (error) {
    console.log(error);
  }
};

export const getwhychooseus = async () => {
  try {
    return await getData(
      `/without-login/about-us/why-choose-us/allwhychooseus`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getHappyCustomers = async () => {
  try {
    return await getData(
      `/without-login/about-us/happy-customers/allhappycustomers`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getProductCount = async () => {
  try {
    return await getData(`/without-login/count-all-products`);
  } catch (error) {
    console.log(error);
  }
};

export const getSupplierCount = async () => {
  try {
    return await getData(`/without-login/count-all-suppliers`);
  } catch (error) {
    console.log(error);
  }
};

export const getCategories = async () => {
  try {
    return await getData(`/without-login/all-category-count`);
  } catch (error) {
    console.log(error);
  }
};

export const getJobs = async () => {
  try {
    return await getData(`/without-login/career/jobs`);
  } catch (error) {
    console.log(error);
  }
};

export const getSingleJobs = async (id) => {
  try {
    return await getData(`/without-login/career/jobs/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const postApplicationForm = async (data) => {
  try {
    return await postData(`/career-application/form`, data);
  } catch (error) {
    console.log(error);
  }
};

export const postInquiryForm = async (data) => {
  try {
    return await postData(`/inquire/form`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getAppSetup = async () => {
  try {
    return await getData(`/admin/home/app-setup/${1}`);
  } catch (error) {
    console.log(error);
  }
};

export const sendMobileOTP = async (contact_no) => {
  try {
    const res = await postData(`/seller/verify-otp/mobile-otp-send`, {
      contact_no,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const resendMobileOTP = async (contact_no) => {
  try {
    const res = await postData(`/without-login/verify-otp/mobile-otp-resend`, {
      contact_no,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailOTP = async (email) => {
  try {
    const res = await postData(`/seller/verify-otp/email-otp-send`, { email });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const verifyMobileOTP = async (contact_no, otp) => {
  try {
    const res = await postData(`/seller/verify-otp/mobile-verify-otp`, {
      contact_no,
      otp,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const verifyEmailOTP = async (email, otp) => {
  try {
    const res = await postData(`/seller/verify-otp/email-verify-otp`, {
      email,
      otp,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};
