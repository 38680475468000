import React, { useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Newly_launch from './Newly_launch';


const Mini_newly_launch = (props) => {
    // const [modalShow, setModalShow] = React.useState(false);

    // const handleMiniPlayerClick = () => {
    //     setModalShow(true);
    //     props.onHide();
    // };



    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>


            {/* <Newly_launch 
                show={modalShow}
                onHide={() => setModalShow(false)}
            /> */}


        </>
    )
}

export default Mini_newly_launch