import React from "react";
import Purchase from "./Purchase/Purchase";

function Account() {
  return (
    <>
      <Purchase />
    </>
  );
}

export default Account;
