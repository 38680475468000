import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import "./SelectFromCategories.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Product_Card from "../Common-Component/Product_Card/Product_Card";
import Popular_Product_Card from "../Common-Component/Popular_Product_Card/Popular_Product_Card";
import { useParams } from "react-router-dom";
import { Context } from "../../utils/context";
import {
  Category,
  ChildSubCategory,
  Products,
  SubCategory,
} from "../../utils/apis/Product/Product";
library.add(fas);

const SelectFromCategories = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [numToShow, setNumToShow] = useState(20); // Number of products to show initially
  const [showAll, setShowAll] = useState(false);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [childCategory, setChildCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const { IMG_URL, shimmerLoader, setShimmerLoader } = useContext(Context);
  const { id } = useParams();

  // Fetch categories on load
  const getCategory = async () => {
    try {
      setShimmerLoader(true);
      const res = await Category();
      if (res?.success) {
        getProduct(id);
        setSubCategory(id);
        setCategory(res.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setShimmerLoader(false);
    }
  };

  // Fetch subcategories for a selected category
  const getSubCategory = async (categoryId) => {
    setShimmerLoader(true);
    const res = await SubCategory(categoryId);
    if (res?.success) {
      console.log("SubCategory Data:", res.data); // Check what is being returned
      setSubCategory(res.data || []); // Fallback to an empty array if res.data is not an array
    }
    setShimmerLoader(false);
  };

  // Fetch child categories (or products) for a selected subcategory
  const getChildCategory = async (subCategoryId) => {
    setShimmerLoader(true);
    const res = await ChildSubCategory(subCategoryId);
    if (res?.success) {
      setChildCategory(res.data);
      setShimmerLoader(false);
    }
  };

  // Fetch products for a selected category/subcategory/child-category
  const getProduct = async (id) => {
    setShimmerLoader(true);
    const res = await Products(id);
    if (res?.success) {
      setProducts(res?.data?.data);
      setShimmerLoader(false);
    }
  };

  // When a category is clicked, fetch subcategories and products
  const handleCategoryClick = (categoryId) => {
    getSubCategory(categoryId);
    getProduct(categoryId); // Fetch products directly when category is clicked
  };

  // When a subcategory is clicked, fetch child categories and products
  const handleSubCategoryClick = (subCategoryId) => {
    getChildCategory(subCategoryId);
    getProduct(subCategoryId); // Fetch products for this subcategory
  };

  // Toggle the view for showing all products or fewer
  const toggleShowAll = () => {
    setShowAll(!showAll);
    setNumToShow(showAll ? 8 : products.length);
  };

  useEffect(() => {
    getCategory();
  }, [id]);

  return (
    <section className="select-categories">
      <Container>
        <div className="mb-4">
          <h2 className="title">All Category</h2>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey={id}>
          <Row className="me-0 ms-0 ">
            <div variant="pills" className="flex-row p-0 mb-3">
              <Swiper
                className="mySwiper1 "
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                breakpoints={{
                  0: { slidesPerView: 1.5, spaceBetween: 10 },
                  420: { slidesPerView: 2, spaceBetween: 10 },
                  768: { slidesPerView: 3.5, spaceBetween: 10 },
                  992: { slidesPerView: 4.5, spaceBetween: 10 },
                  1200: { slidesPerView: 5.5, spaceBetween: 10 },
                }}
              >
                {category?.map((slide, index) => (
                  <SwiperSlide key={index} className="product-slide">
                    <Nav.Item>
                      <Nav.Link
                        eventKey={slide?.id}
                        onClick={() => handleCategoryClick(slide?.id)} // Fetch subcategories and products on click
                      >
                        <Popular_Product_Card
                          className="product-image"
                          content={slide?.name}
                          image={IMG_URL + slide?.image}
                          imgClassName="category-image"
                        />
                        <div className="triangle-right"></div>
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            {subCategory.length > 0 && (
              <>
                <h2 className="title">All Sub Category</h2>
                <div variant="pills" className="flex-row p-0  mb-3">
                  <Swiper
                    className="mySwiper1 "
                    breakpoints={{
                      0: { slidesPerView: 1.5, spaceBetween: 10 },
                      420: { slidesPerView: 2, spaceBetween: 10 },
                      768: { slidesPerView: 3.5, spaceBetween: 10 },
                      992: { slidesPerView: 4.5, spaceBetween: 10 },
                    }}
                  >
                    {Array.isArray(subCategory) &&
                      subCategory.map((slide, index) => (
                        <SwiperSlide key={index} className="product-slide">
                          <Nav.Item>
                            <Nav.Link
                              eventKey={slide?.id}
                              onClick={() => handleSubCategoryClick(slide?.id)}
                            >
                              <Popular_Product_Card
                                className="product-image"
                                content={slide?.name}
                                image={IMG_URL + slide?.image}
                                imgClassName="category-image"
                              />
                              <div className="triangle-right"></div>
                            </Nav.Link>
                          </Nav.Item>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </>
            )}

            {/* Products Section */}
            <div className="desktop_view_product_category_slider slider-holder">
              <Row>
                {products.length > 0 ? (
                  products.slice(0, numToShow).map((product, index) => {
                    const price = product?.product_multiple_mains?.[0]
                      ?.product_multiple_variants?.[0]?.s_price
                      ? product?.product_multiple_mains?.[0]
                          ?.product_multiple_variants?.[0]?.s_price
                      : product?.product_variants?.[0]?.s_price;

                    return (
                      <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                        <Product_Card
                          image={IMG_URL + product?.image1}
                          text={product?.name}
                          product_id={product?.id}
                          subText={product?.short_description}
                          price={price}
                          variant_id={product?.product_variants?.[0]?.id}
                          m_variant_id={
                            product?.product_multiple_mains?.[0]
                              ?.product_multiple_variants[0]?.id
                          }
                        />
                      </Col>
                    );
                  })
                ) : (
                  <div className="empty-heading-holder">
                    <h3>No Product With This Category</h3>
                  </div>
                )}
              </Row>
            </div>

            {/* View All/ View Less Button */}
            {(numToShow < products.length || showAll) && (
              <div className="view-all mb-5" onClick={toggleShowAll}>
                <p>{showAll ? "View Less" : "View All"}</p>
              </div>
            )}
          </Row>
        </Tab.Container>
      </Container>
    </section>
  );
};

export default SelectFromCategories;
